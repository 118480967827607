


























































import { Workshop } from "@/models/workshop.model";
import Vuetable from "vuetable-2";
import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import WorkshopReservationsService from "@/services/WorkshopReservationsService";

const workshopModule = namespace("Workshops");

@Component({ components: { Vuetable } })
export default class WorkkshopDetail extends Vue {
  isLoading = false;
  fields = [
    { name: "active", sortField: "Active" },
    { name: "title", sortField: "Link" },
    { name: "number_of_seats", sortField: "Link" },
    { name: "reserved_reservations_count", sortField: "Link" },
    { name: "actions", sortField: "Actions" },
  ];

  @workshopModule.Getter("all")
  workshops!: Workshop[];

  @workshopModule.Action("fetchAll")
  fetchAll!: () => Promise<Workshop[]>;

  @workshopModule.Action("delete")
  delete!: (workshop: string) => Promise<Workshop>;

  get locale() {
    return this.$root.$i18n.locale;
  }

  async doFetchAll() {
    this.isLoading = true;
    await this.fetchAll();
    this.isLoading = false;
  }

  doCreate() {
    this.$router.push({ name: "workshop-create" });
  }

  async doExport() {
    const response = await WorkshopReservationsService.export(this.locale);
    const blob = new Blob([response], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "workshopReservations.xlsx";
    link.click();
    URL.revokeObjectURL(link.href);
  }

  doEdit(workshop: Workshop) {
    this.$router.push({ name: "workshop-detail", params: { id: workshop.id } });
  }

  async doDelete(workshop: Workshop) {
    this.isLoading = true;
    const isConfirmed = await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to remove ${workshop.title}?`,
      {
        title: "Confirm delete",
        okVariant: "danger",
        centered: true,
      }
    );
    if (isConfirmed) {
      await this.delete(workshop.id);
    }
    this.isLoading = false;
    this.doFetchAll();
  }

  created() {
    this.doFetchAll();
  }
}

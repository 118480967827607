import { WorkshopReservation } from "@/models/workshop-reservation.model";
import axios from "@/plugins/axios.plugin";
import BaseService from "./base-service";

class WorkshopReservationsService extends BaseService<WorkshopReservation> {
  get endpoint() {
    return "/workshop-reservations";
  }

  async export(language: string): Promise<any> {
    const response = await axios.get<any>(`${this.endpoint}/export`, {
      responseType: "arraybuffer",
      params: { language },
    });
    return response.data;
  }
}

export default new WorkshopReservationsService();
